import React, {useState, useEffect} from 'react';
import {
    Badge,
    Box,
    Chip,
    Container,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import * as Realm from "realm-web";
import {app} from '../db/realmConfig';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from "@mui/material/Skeleton";
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const TokenList = () => {
    const [tokens, setTokens] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [priceIncrease, setPriceIncrease] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        setIsLoading(true)
        const fetchSettings = async () => {
            setIsLoading(true)
            const credentials = Realm.Credentials.anonymous();
            try {
                const user = await app.logIn(credentials);
                const settingsData = await user.functions.readSettings();
                const tokensData = await user.functions.getAllTokens();
                const walletsData = await user.functions.getAllWallets();
                setWallets(walletsData);
                setTokens(tokensData);
                setPriceIncrease(settingsData.find(s => s.name === 'priceIncrease')?.value || 1)

            } catch (err) {
                console.error("Error fetching settings:", err);
            } finally {
                setIsLoading(false)
            }
        };

        fetchSettings();
    }, []);

    const handleChangeTokenPrice = async (tokenName, increment) => {
        setIsChange(true)
        const credentials = Realm.Credentials.anonymous();
        try {
            const user = await app.logIn(credentials);
            console.log(tokenName, increment)
            await user.functions.changeTokenPrice(tokenName, increment);
            // Обновление данных после изменения цены токена
            const tokensData = await user.functions.getAllTokens();
            setTokens(tokensData);

        } catch (err) {
            console.error(err);
        } finally {
            setIsChange(false)
        }
    };
    const filteredTokens = tokens.filter(t =>
        t.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleReset = () => {
        setSearchTerm('');
    };
    const calculateTokenInWallets = (token, wallets) => {
        let total = 0;
        if (!Array.isArray(wallets)) {
            console.error('wallets is not an array:', wallets);
            return total;
        }
        wallets.forEach(wallet => {
            if (!wallet.tokens || !Array.isArray(wallet.tokens)) {
                console.error('Invalid tokens in wallet:', wallet);
                return;
            }
            const tokenInWallet = wallet.tokens.find(t => t.token_Id.toString() === token._id.toString());
            if (tokenInWallet) {
                if (typeof tokenInWallet.amount !== 'number') {
                    console.error('Invalid amount for token in wallet:', tokenInWallet);
                    return;
                }
                total += tokenInWallet.amount;
            }
        });
        return Math.round(total).toString();
    };
    return (
        <Container>
            {isLoading &&
                <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'}
                          height={80}/>}
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTerm}
                                   onChange={(e) => setSearchTerm(e.target.value)}/>
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>

                </Grid>
            </Grid>

            {filteredTokens.map((token, index) => {
                const tokenInWallets = calculateTokenInWallets(token, wallets);
                return (
                    <Grid container key={token._id.toString()} spacing={1} justifyContent="center" style={{margin: '1% 0', padding: '5px', border: '1px solid gray'}}>
                        <Grid item xs={12} >
                            <Box display="flex" alignItems="center">
                                <Badge badgeContent={tokenInWallets} color="warning" max={999}>
                                    {token.name}⠀
                                </Badge>
                                ⠀= 1/{token.price} QCC
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                style={{margin: '5px'}}
                                loading={isChange}
                                loadingPosition="start"
                                size="large"
                                startIcon={<RemoveCircleIcon/>}
                                variant="contained"
                                onClick={() => handleChangeTokenPrice(token.name, priceIncrease)}
                                disabled={token.name === 'QCCoin'}
                            ></LoadingButton>
                            <Chip label={`${parseFloat((1 / token.price).toFixed(7))} QCC`} color="primary"/>
                            <LoadingButton
                                style={{margin: '5px'}}
                                loading={isChange}
                                loadingPosition="start"
                                size="large"
                                startIcon={<AddCircleIcon fontSize="inherit"/>}
                                variant="contained"
                                disabled={token.name === 'QCCoin'}
                                onClick={() => handleChangeTokenPrice(token.name, -priceIncrease)}
                            ></LoadingButton>
                        </Grid>

                    </Grid>
                )
            })}

        </Container>
    );
};

export default TokenList;
