import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminPanel from './pages/AdminPanel';
import PinCodeInput from './pages/PinCodeInput';
import { ThemeContextProvider } from './components/ThemeContext';
import {CssBaseline} from '@mui/material';
import TokenRanking from "./pages/TokenRanking";
import UserTokenList from "./pages/UserTokenList";
import TestConnection from "./pages/TestConnection";
const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleAuthentication = (status) => {
        setIsAuthenticated(status);
    };

    return (
        <ThemeContextProvider>
            <CssBaseline/>
            <Router>
                <Routes>
                    <Route path="/tokens" element={<TokenRanking />} />
                    <Route path="/test" element={<TestConnection />} />
                    <Route path="/users" element={<UserTokenList />} />
                    <Route path="/admin" element={isAuthenticated ? <AdminPanel /> : <Navigate to="/admin-pin" />} />
                    <Route path="/admin-pin" element={<PinCodeInput onAuthenticate={handleAuthentication} />} />
                    <Route path="/" element={<Navigate to="/tokens" />} />
                </Routes>
            </Router>
        </ThemeContextProvider>
    );
};

export default App;
