import React from 'react';
import {AppBar, Toolbar, Typography, IconButton, Box, Container} from '@mui/material';
import { Link } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {useThemeContext} from "./ThemeContext";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
const UserBar = ({title}) => {
    const { darkMode, toggleDarkMode } = useThemeContext();
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' },alignItems: 'center' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' },alignItems: 'center' }}>
                        <IconButton component={Link} to="/tokens" color="inherit" aria-label="tokens" size="large">
                            <CurrencyBitcoinIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton component={Link} to="/users" color="inherit" aria-label="users" size="large">
                            <AccountBalanceWalletIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton component={Link} to="/admin-pin" color="inherit" aria-label="admin panel" size="large">
                            <AdminPanelSettingsIcon fontSize="inherit"/>
                        </IconButton>
                        <IconButton onClick={toggleDarkMode} aria-label="toggle theme"  size="large">
                            {darkMode ? <Brightness7Icon fontSize="inherit"/> : <Brightness4Icon fontSize="inherit"/>}
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

    );
};

export default UserBar;
