import React, { useState, useEffect } from 'react';
import {
    Typography,
    Avatar,
    Container,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Box, IconButton, Chip
} from '@mui/material';
import { app } from '../db/realmConfig';
import * as Realm from "realm-web";
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import Skeleton from "@mui/material/Skeleton";
import {AccountCircle} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

const UserTokenList = () => {
    const [users, setUsers] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const credentials = Realm.Credentials.anonymous();
            try {
                const user = await app.logIn(credentials);
                const usersData = await user.functions.getAllUsers();
                const tokensData = await user.functions.getAllTokens();
                const walletsData = await user.functions.getAllWallets();
                setUsers(usersData);
                setTokens(tokensData);
                setWallets(walletsData);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load data");
            }finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const getUserWalletTokens = (userId) => {
        const userWallet = wallets.find(wallet => wallet.userId.toString() === userId.toString());
        if (userWallet) {
            return userWallet.tokens.map(userToken => {
                const tokenDetails = tokens.find(token => token._id.toString() === userToken.token_Id.toString());
                const totalPrice = userToken.amount * (1/tokenDetails?.price); // calculate total price for each token
                return { ...userToken, name: tokenDetails?.name || 'Unknown', totalPrice };
            });
        }
        return [];
    };

    const filteredUsers = users
        .filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            const totalPriceA = getUserWalletTokens(a._id).reduce((sum, t) => sum + t.totalPrice, 0);
            const totalPriceB = getUserWalletTokens(b._id).reduce((sum, t) => sum + t.totalPrice, 0);

            if (totalPriceA !== totalPriceB) {
                return totalPriceB - totalPriceA; // sort by total price in descending order
            }

            return a.name.localeCompare(b.name); // sort by name in ascending order
        });

    const handleReset = () => {
        setSearchTerm('');
    };

    return (
        <Container>
            <UserBar title={'Гаманці'}/>
            <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '20px 0'}}>
                <Grid item xs={12} sm={8}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField id="input-with-sx" label="Пошук" variant="standard"
                                   value={searchTerm}
                                   onChange={(e) => setSearchTerm(e.target.value)}/>
                        {searchTerm && <IconButton onClick={handleReset} color="inherit" size="small">
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>}
                    </Box>
                </Grid>
            </Grid>
            {error && `<Typography color="error">{error}</Typography>`}
            {isLoading && <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'} height={200} />}
            {filteredUsers.map((user, index) => (
                <Card elevation={8} style={{margin:'5px', padding:'10px', borderRadius: '15px' }} key={index}>
                    <CardHeader style={{padding: '8px 16px'}}
                                avatar={
                            <Avatar aria-label="recipe">
                                {user.name.split(' ').map(n => n[0]).join('')}
                            </Avatar>
                        }
                                title={user.name}
                    />
                    <CardContent>
                        <Grid container spacing={2} alignItems="left" justifyContent="left" style={{margin: '1px 0'}}>
                            {getUserWalletTokens(user._id).map((token, idx) => (
                                token.amount > 0 &&
                                    <Grid item key={idx}>
                                        <Chip label={`${token.name}: ${parseFloat(token.amount.toFixed(7))}`}
                                              variant="outlined"/>
                                    </Grid>
                            ))}
                        </Grid>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{margin: '10px 5px'}}>
                            Загальна цінність активів: {getUserWalletTokens(user._id).reduce((sum, t) => sum + t.totalPrice, 0).toFixed(2)} QCC
                        </Typography>

                    </CardContent>
                </Card>
            ))}
            <Footer />
        </Container>
    );
};

export default UserTokenList;
