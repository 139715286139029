import React, { useState, useEffect } from 'react';
import {
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
    Chip,
    Paper, lighten, Badge
} from '@mui/material';
import { app } from '../db/realmConfig';
import * as Realm from "realm-web";
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from '@mui/material/styles';

const TokenRanking = () => {
    const [tokens, setTokens] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [wallets, setWallets] = useState([]);

    const theme = useTheme();

    useEffect(() => {
        const fetchTokens = async () => {
            setIsLoading(true);
            const credentials = Realm.Credentials.anonymous();
            try {
                const user = await app.logIn(credentials);
                const tokensData = await user.functions.getAllTokens();
                const walletsData = await user.functions.getAllWallets();
                setTokens(tokensData.filter(t => t.name !== 'QCCoin').sort((a, b) => a.price - b.price));
                setWallets(walletsData);
            } catch (err) {
                console.error("Error fetching tokens:", err);
                setError("Failed to load token data");
            } finally {
                setIsLoading(false);
            }
        };

        fetchTokens();
    }, []);

    const calculateTokenInWallets = (token, wallets) => {
        let total = 0;
        if (!Array.isArray(wallets)) {
            console.error('wallets is not an array:', wallets);
            return total;
        }
        wallets.forEach(wallet => {
            if (!wallet.tokens || !Array.isArray(wallet.tokens)) {
                console.error('Invalid tokens in wallet:', wallet);
                return;
            }
            const tokenInWallet = wallet.tokens.find(t => t.token_Id.toString() === token._id.toString());
            if (tokenInWallet) {
                if (typeof tokenInWallet.amount !== 'number') {
                    console.error('Invalid amount for token in wallet:', tokenInWallet);
                    return;
                }
                total += tokenInWallet.amount;
            }
        });
        return Math.round(total).toString();
    };

    const minPrice = tokens.length ? Math.min(...tokens.map(token => token.price)) : 0;

    return (
        <Container>
            <UserBar title={'Ціни'} />
            {error && <Typography color="error">{error}</Typography>}
            {isLoading && <Skeleton animation="wave" style={{ margin: '2% 0', padding: '15px' }} variant="rounded" width={'100%'} height={80} />}
            <Grid container spacing={1} justifyContent="center" style={{ margin: '1% 0', padding: '5px' }}>
                {tokens.map((token, index) => {
                    const fillPercentage = minPrice ? (minPrice / token.price) * 100 : 0;
                    const tokenInWallets = calculateTokenInWallets(token, wallets);
                    return (
                        <Grid item xs={12} sm={12} md={12} key={token._id.toString()}>
                            <Paper
                                elevation={6}
                                style={{
                                    margin: '5px',
                                    padding: '10px',
                                    borderRadius: '15px',
                                    background: `linear-gradient(to right, ${theme.palette.success.dark} ${fillPercentage}%, ${lighten(theme.palette.background.paper, 0.2)} ${fillPercentage}%)`
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar sx={{ width: 30, height: 30, bgcolor: theme.palette.success.light, color: theme.palette.text, border: '2px solid #9f9f9f' }}>{index + 1}</Avatar>
                                    <Box ml={2}>
                                        <Typography variant="h6" color="primary">
                                            <Badge badgeContent={tokenInWallets} color="warning"  max={999}>
                                                {token.name}
                                            </Badge>
                                        </Typography>
                                    </Box>
                                    <Box ml={2}>
                                        <Chip label={`${parseFloat((1/token.price).toFixed(7))} QCC (1/${token.price})`} color="primary" />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
            <Footer />
        </Container>
    );
};

export default TokenRanking;
