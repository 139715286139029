import React, { useState, useEffect } from 'react';
import { Typography, TextField, List, ListItem, ListItemText } from '@mui/material';
import { app } from '../db/realmConfig';
import * as Realm from "realm-web";
import Skeleton from "@mui/material/Skeleton";
import LoadingButton from "@mui/lab/LoadingButton";

const Settings = () => {
    const [settings, setSettings] = useState([]);
    const [editedSettings, setEditedSettings] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    useEffect(() => {
        const fetchSettings = async () => {
            setIsLoading(true);
            const credentials = Realm.Credentials.anonymous();
            try {
                const user = await app.logIn(credentials);
                const settingsData = await user.functions.readSettings();
                setSettings(settingsData);
                // Для редактирования сохраняем копию настроек
                const edited = {};
                settingsData.forEach(setting => {
                    edited[setting._id.toString()] = setting.value;
                });
                setEditedSettings(edited);
            } catch (err) {
                console.error("Error fetching settings:", err);
                setError("Failed to load settings");
            }finally {
                setIsLoading(false)
            }
        };

        fetchSettings();
    }, []);

    const handleInputChange = (id, newValue) => {
        setEditedSettings(prevState => ({
            ...prevState,
            [id]: newValue
        }));
    };

    const handleSaveSettings = async () => {
        setIsChanged(true);
        const credentials = Realm.Credentials.anonymous();
        try {
            const user = await app.logIn(credentials);
            // Преобразуем editedSettings обратно в формат настроек для сохранения
            const updatedSettings = settings.map(setting => ({
                ...setting,
                value: editedSettings[setting._id.toString()]
            }));
            // Сохраняем обновленные настройки
            console.log(updatedSettings)
            await user.functions.saveSettings(updatedSettings);
            setError('');
        } catch (err) {
            console.error("Error saving settings:", err);
            setError("Failed to save settings");
        }finally {
            setIsChanged(false);
        }
    };

    return (
        <div>
            {error && <Typography color="error">{error}</Typography>}
            {isLoading && settings.length === 0 && <Skeleton animation="wave" style={{margin: '2% 0', padding: '15px'}} variant="rounded" width={'100%'} height={80} />}
            <List>
                {settings.map(setting => (
                    <ListItem key={setting._id.toString()}>
                        <ListItemText primary={setting.description} />
                        <TextField
                            value={editedSettings[setting._id.toString()]}
                            onChange={(e) => handleInputChange(setting._id.toString(), e.target.value)}
                            style={{ marginLeft: '1rem', width: '10rem' }}
                            disabled={isChanged}
                        />
                    </ListItem>
                ))}
            </List>
            <LoadingButton
                style={{ margin: '5px', marginTop: '1rem' }}
                loading={isChanged}
                size="large"
                variant="contained"
                onClick={() => handleSaveSettings()}
            >
                Зберегти
            </LoadingButton>
        </div>
    );
};

export default Settings;
