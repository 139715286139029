import React, { useState } from 'react';
import { Container, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import { app } from '../db/realmConfig';
import * as Realm from "realm-web";
import LoadingButton from "@mui/lab/LoadingButton";

const PinCodeInput = ({ onAuthenticate }) => {
    const [pin, setPin] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const handlePinChange = (event) => {
        setPin(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const credentials = Realm.Credentials.anonymous();
        try {
            const user = await app.logIn(credentials);
            const result = await user.functions.verifyPin(pin);
            if (result) {
                onAuthenticate(true);
                navigate('/admin');
            } else {
                setError(true);
            }
        } catch (err) {
            console.error("Error verifying PIN:", err);
            setError(true);
        }finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <UserBar title={'Вхід'}/>
            <TextField
                type="password"
                value={pin}
                onChange={handlePinChange}
                error={error}
                helperText={error ? 'Incorrect PIN' : ''}
                label="PIN Code"
                variant="outlined"
                fullWidth
                margin="normal"
                disabled={!!isLoading}
            />
            <LoadingButton
                style={{ margin: '5px' }}
                loading={isLoading}
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
            >
                Перевірити
            </LoadingButton>
            <Footer />
        </Container>
    );
};

export default PinCodeInput;
