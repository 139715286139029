import React, { useEffect, useState } from 'react';
import * as Realm from "realm-web";
import { app } from '../db/realmConfig';

const TestConnection = () => {
    const [status, setStatus] = useState('Connecting...');

    useEffect(() => {
        const testConnection = async () => {
            const credentials = Realm.Credentials.anonymous();
            try {
                const user = await app.logIn(credentials);
                setStatus(user.id);
            } catch (err) {
                console.error(err);
                setStatus('Connection failed');
            }
        };

        testConnection().then();
    }, []);

    return <p>{status}</p>;
};

export default TestConnection;
