import React, { useState } from 'react';
import { Container, Menu, MenuItem, Button, Box } from '@mui/material';
import UserBar from "../components/UserBar";
import Footer from "../components/Footer";
import UserList from "../widget/UserList";
import TokenList from "../widget/TokenList";
import TransferTokensForm from "../widget/TransferTokensForm";
import Market from "../widget/Market";
import Settings from "../widget/Settings";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AdminPanel = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState('Токени');

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (menu) => {
        setSelectedMenu(menu);
        setAnchorEl(null);
    };

    const renderContent = () => {
        switch (selectedMenu) {
            case 'Токени':
                return <TokenList />;
            case 'Переказ':
                return <TransferTokensForm />;
            case 'Купівля':
                return <Market />;
            case 'Налаштування':
                return <Settings />;
            case 'Нікнейми':
                return <UserList />;
            default:
                return null;
        }
    };

    return (
        <Container>
            <UserBar title={'Адмінка'} />
            <Box sx={{ mt: 3 }}>
                <Button
                    variant="outlined"
                    onClick={handleMenuOpen}
                    sx={{
                        width: '100%',
                        height: '50px',
                        fontSize: '1.5rem',
                        padding: '10px 20px',
                    }}
                >
                    {selectedMenu} <KeyboardArrowDownIcon/>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    sx={{
                        '& .MuiMenuItem-root': {
                            width: '100%',
                            height: '45px',
                            fontSize: '1.4rem',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                        },
                    }}
                >
                    <MenuItem onClick={() => handleMenuItemClick('Токени')}>Токени</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Переказ')}>Переказ</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Купівля')}>Купівля</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Налаштування')}>Налаштування</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Нікнейми')}>Нікнейми</MenuItem>
                </Menu>

            </Box>
            <Box sx={{ mt: 2 }}>
                {renderContent()}
            </Box>
            <Footer />
        </Container>
    );
};

export default AdminPanel;
